<template>
  <div class="danger-source" ref="dangerSource">
    <div class="function-buttons" ref="functionButtons" v-show="false">
      <el-button type="primary" size="small"></el-button>
    </div>
    <div class="form-area" ref="form">
      <el-form
        class="demo-form-inline"
        ref="form"
        :model="form"
        label-position="left"
        :inline="true"
      >
        <el-form-item>
          <el-input
            style="width: 200px; margin-right: 10px"
            v-model="form.keyWord"
            placeholder="请输入路段名称搜索"
          ></el-input>
        </el-form-item>

        <el-form-item label-width="0">
          <el-button size="small" type="primary" @click="search"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
      id="outputTable"
      :data="tableData"
      stripe
      :height="tableHeight"
      width="100%"
    >
      <el-table-column label="序号" type="index" width="70"></el-table-column>
      <el-table-column
        width="150"
        prop="sectionName"
        label="路段名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        width="150"
        prop="warType"
        label="灾害类型"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        width="120"
        prop="lon"
        label="经度"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        width="120"
        prop="lat"
        label="纬度"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="szbh"
        label="市洲编号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="xqbh"
        label="县区编号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="sz"
        label="乡镇"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="lxbm"
        label="路线编码"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="zh"
        label="桩号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="gtbd"
        label="国土标定"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="yzcd"
        label="严重程度"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="yxfw"
        label="影响范围"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="yhgs"
        label="隐患概述"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="zt"
        label="状态"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="csgs"
        label="措施概述"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="pcsj"
        label="排查时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="dw"
        label="单位"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="lxr"
        label="联系人"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="电话"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        width="160"
        prop="updateTime"
        label="数据更新时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="remarks"
        label="备注"
        :show-overflow-tooltip="true"
      ></el-table-column>
    </el-table>
    <div ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getDangerSourceList } from '@/api/lib/gps-api'

export default {
  name: 'dangerSource',
  data() {
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        keyWord: '',
        currentPage: 1,
        pageSize: 10
      },
      modelObj: null,
      total: 0
    }
  },
  methods: {
    /** 导出 */
    getXLS() {
      getXLS('#outputTable', '运营数据统计')
    },
    // 计算表格高度
    computeHeight() {
      let wholeHeight = this.$refs.dangerSource.clientHeight
      let buttonsHeight = this.$refs.functionButtons.clientHeight
      let formHeight = this.$refs.form.clientHeight
      let paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10
    },
    // 提交
    search() {
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getDataList()
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page
      this.getDataList()
    },
    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size
      this.getDataList()
    },
    // 获取列表
    getDataList() {
      let data = { ...this.form }
      getDangerSourceList(data).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.list
          this.total = res.data.total
          if (res.data.list.length === 0 && data.currentPage !== 1) {
            this.form.currentPage -= 1
            this.getListByField()
          }
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.msg)
        }
      })
    }
  },
  created() {
    this.search()
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.danger-source {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}

.theme-project-gps {
  .danger-source {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
